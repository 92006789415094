// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.70.0/node_modules/css-loader/dist/cjs.js??clonedRuleSet-62[0].rules[0].use[1]!../../node_modules/.pnpm/vue-loader@15.9.8_gwqqbdtstrkazc3c5tmodvvd2i/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/.pnpm/postcss-loader@6.2.1_ekn6crlz27cphneud6qwcz75tm/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-62[0].rules[0].use[2]!../../node_modules/.pnpm/postcss-loader@6.2.1_ekn6crlz27cphneud6qwcz75tm/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-62[0].rules[0].use[3]!../../node_modules/.pnpm/sass-loader@12.6.0_sass@1.53.0/node_modules/sass-loader/dist/cjs.js??clonedRuleSet-62[0].rules[0].use[4]!../../node_modules/.pnpm/vue-loader@15.9.8_gwqqbdtstrkazc3c5tmodvvd2i/node_modules/vue-loader/lib/index.js??vue-loader-options!./Comment.vue?vue&type=style&index=0&lang=scss&shadow");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!../../node_modules/.pnpm/vue-style-loader@4.1.3/node_modules/vue-style-loader/lib/addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("09986da8", content, shadowRoot)
};