class Emoji {
    constructor(emoji, description, aliases, tags) {
        this.emoji = emoji;
        this.description = description;
        this.aliases = aliases;
        this.tags = tags;
    }
}

const emojiImgPath = "https://www.unpkg.com/halo-comment-yu@latest/cdn/img/emoji";

export default [
    new Emoji(`<img alt="smile" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_weixioa02_org.png">`, "", " :smile: ", []),
    new Emoji(`<img alt="lovely" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_keai_org.png">`, "", " :lovely: ", []),
    new Emoji(`<img alt="happy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_taikaixin_org.png">`, "", " :happy: ", []),
    new Emoji(`<img alt="clap" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_guzhang_thumb.png">`, "", " :clap: ", []),
    new Emoji(`<img alt="whee" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_xixi_thumb.png">`, "", " :whee: ", []),
    new Emoji(`<img alt="haha" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_haha_thumb.png">`, "", " :haha: ", []),
    new Emoji(`<img alt="laugh and cry" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_xiaoku_thumb.png">`, "", " :laugh and cry: ", []),
    new Emoji(`<img alt="wink" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_jiyan_org.png">`, "", " :wink: ", []),
    new Emoji(`<img alt="greddy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_chanzui_org.png">`, "", " :greddy: ", []),
    new Emoji(`<img alt="awkward" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_heixian_thumb.png">`, "", " :awkward: ", []),
    new Emoji(`<img alt="sweat" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_han_org.png">`, "", " :sweat: ", []),
    new Emoji(`<img alt="pick nose" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_wabi_thumb.png">`, "", " :pick nose: ", []),
    new Emoji(`<img alt="hum" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_heng_thumb.png">`, "", " :hum: ", []),
    new Emoji(`<img alt="angry" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_nu_thumb.png">`, "", " :angry: ", []),
    new Emoji(`<img alt="grievance" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_weiqu_thumb.png">`, "", " :grievance: ", []),
    new Emoji(`<img alt="poor" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_kelian_org.png">`, "", " :poor: ", []),
    new Emoji(`<img alt="disappoint" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_shiwang_thumb.png">`, "", " :disappoint: ", []),
    new Emoji(`<img alt="sad" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_beishang_org.png">`, "", " :sad: ", []),
    new Emoji(`<img alt="tear" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_leimu_org.png">`, "", " :tear: ", []),
    new Emoji(`<img alt="no way" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_kuxiao_org.png">`, "", " :no way: ", []),
    new Emoji(`<img alt="shy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_haixiu_org.png">`, "", " :shy: ", []),
    new Emoji(`<img alt="dirt" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_wu_thumb.png">`, "", " :dirt: ", []),
    new Emoji(`<img alt="love you" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_aini_org.png">`, "", " :love you: ", []),
    new Emoji(`<img alt="kiss" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_qinqin_thumb.png">`, "", " :kiss: ", []),
    new Emoji(`<img alt="amorousness" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_huaxin_org.png">`, "", " :amorousness: ", []),
    new Emoji(`<img alt="longing" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_chongjing_org.png">`, "", " :longing: ", []),
    new Emoji(`<img alt="desire" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_tianping_thumb.png">`, "", " :desire: ", []),
    new Emoji(`<img alt="bad laugh" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_huaixiao_org.png">`, "", " :bad laugh: ", []),
    new Emoji(`<img alt="blackness" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_yinxian_org.png">`, "", " :blackness: ", []),
    new Emoji(`<img alt="laugh without word" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_xiaoerbuyu_org.png">`, "", " :laugh without word: ", []),
    new Emoji(`<img alt="titter" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_touxiao_org.png">`, "", " :titter: ", []),
    new Emoji(`<img alt="cool" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_ku_org.png">`, "", " :cool: ", []),
    new Emoji(`<img alt="not easy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_bingbujiandan_thumb.png">`, "", " :not easy: ", []),
    new Emoji(`<img alt="think" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_sikao_org.png">`, "", " :think: ", []),
    new Emoji(`<img alt="question" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_ningwen_org.png">`, "", " :question: ", []),
    new Emoji(`<img alt="no idea" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_wenhao_thumb.png">`, "", " :no idea: ", []),
    new Emoji(`<img alt="dizzy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_yun_thumb.png">`, "", " :dizzy: ", []),
    new Emoji(`<img alt="bomb" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_shuai_thumb.png">`, "", " :bomb: ", []),
    new Emoji(`<img alt="bone" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_kulou_thumb.png">`, "", " :bone: ", []),
    new Emoji(`<img alt="be quiet" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_xu_org.png">`, "", " :be quiet: ", []),
    new Emoji(`<img alt="shut up" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_bizui_org.png">`, "", " :shut up: ", []),
    new Emoji(`<img alt="stupid" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_shayan_org.png">`, "", " :stupid: ", []),
    new Emoji(`<img alt="surprise " referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_chijing_org.png">`, "", " :surprise : ", []),
    new Emoji(`<img alt="vomit" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_tu_org.png">`, "", " :vomit: ", []),
    new Emoji(`<img alt="cold" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_kouzhao_thumb.png">`, "", " :cold: ", []),
    new Emoji(`<img alt="sick" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_shengbing_thumb.png">`, "", " :sick: ", []),
    new Emoji(`<img alt="bye" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_baibai_thumb.png">`, "", " :bye: ", []),
    new Emoji(`<img alt="look down on" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_bishi_org.png">`, "", " :look down on: ", []),
    new Emoji(`<img alt="white eye" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_landelini_org.png">`, "", " :white eye: ", []),
    new Emoji(`<img alt="left hum" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_zuohengheng_thumb.png">`, "", " :left hum: ", []),
    new Emoji(`<img alt="right hum" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_youhengheng_thumb.png">`, "", " :right hum: ", []),
    new Emoji(`<img alt="crazy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_zhuakuang_org.png">`, "", " :crazy: ", []),
    new Emoji(`<img alt="scold " referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_zhouma_thumb.png">`, "", " :scold : ", []),
    new Emoji(`<img alt="hit on face" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_dalian_org.png">`, "", " :hit on face: ", []),
    new Emoji(`<img alt="wow" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_ding_org.png">`, "", " :wow: ", []),
    new Emoji(`<img alt="fan" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_hufen02_org.png">`, "", " :fan: ", []),
    new Emoji(`<img alt="money" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_qian_thumb.png">`, "", " :money: ", []),
    new Emoji(`<img alt="yawn" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_dahaqian_org.png">`, "", " :yawn: ", []),
    new Emoji(`<img alt="sleepy" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_kun_thumb.png">`, "", " :sleepy: ", []),
    new Emoji(`<img alt="sleep" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_shuijiao_thumb.png">`, "", " :sleep: ", []),
    new Emoji(`<img alt="watermelon " referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_chigua_thumb.png">`, "", " :watermelon : ", []),
    new Emoji(`<img alt="doge" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_doge02_org.png">`, "", " :doge: ", []),
    new Emoji(`<img alt="dog" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_erha_org.png">`, "", " :dog: ", []),
    new Emoji(`<img alt="cat" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_miaomiao_thumb.png">`, "", " :cat: ", []),
    new Emoji(`<img alt="thumb" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_zan_org.png">`, "", " :thumb: ", []),
    new Emoji(`<img alt="good" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_good_org.png">`, "", " :good: ", []),
    new Emoji(`<img alt="ok" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_ok_org.png">`, "", " :ok: ", []),
    new Emoji(`<img alt="yeah" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_ye_thumb.png">`, "", " :yeah: ", []),
    new Emoji(`<img alt="shack hand" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_woshou_thumb.png">`, "", " :shack hand: ", []),
    new Emoji(`<img alt="bow" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_zuoyi_org.png">`, "", " :bow: ", []),
    new Emoji(`<img alt="come" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_guolai_thumb.png">`, "", " :come: ", []),
    new Emoji(`<img alt="punch" referrerpolicy="no-referrer" class="vemoji" src="${emojiImgPath}/2018new_quantou_thumb.png">`, "", " :punch: ", [])
];
