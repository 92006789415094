import { render, staticRenderFns } from "./CommentLoading.vue?vue&type=template&id=950c2936&"
import script from "./CommentLoading.vue?vue&type=script&lang=js&"
export * from "./CommentLoading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_gwqqbdtstrkazc3c5tmodvvd2i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports